import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  paperCenter: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  boxItem: {
    background: "rgba(17,39,255, 0.02)",
    height: "100%",
    boxSizing: "content-box",
  },
  boxItemInner: {
    padding: "15px",
  },
  divider: {
    height: "8px",
  },
  lightText: { fontSize: "12px", color: "#909196" },
  squeezedTitle: { fontSize: "24px", fontWeight: 600, margin: "0 0 0 0" },
  subTitle: { fontSize: "16px" },
  textCenter: { textAlign: "center", display: "block" },
  pullUp: {
    marginTop: "-20px",
  },
  chartShort: {
    marginTop: "-30px",
    marginBottom: "-100px",
  },
  allCapsTitle: {
    fontSize: "24px",
    fontWeight: "600",
    margin: "0px",
    textTransform: "uppercase",
  },
  fontSizeNormal: {
    fontSize: "100%",
  },
});

class StaticReport extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    this.props.getReport(this.props.reportId);
    document.title = `5pulses Report ${this.props.reportData.inviteId}`;
  };

  render() {
    const { classes } = this.props;
    const { ReportComponent } = this.props;

    return (
      <div className={classes.root}>
        <ReportComponent
          classes={classes}
          reportData={this.props.reportData}
          copy={this.props.copy}
        />
      </div>
    );
  }
}

export default withStyles(styles)(StaticReport);
