import React, { Component } from "react";

import Dashboard from "./containers/Dashboard";
import "./App.css";

class App extends Component {
  render() {
    return <Dashboard />;
  }
}

export default App;
