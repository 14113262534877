import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Grid from "@material-ui/core/Grid";
import axios from "axios";

import Routes from "../routes";

const drawerWidth = 240;

const reportDumyData = {
  energy: 97,
  physical: 87,
  mental: 77,
  emotional: 67,
  soul: 57,
  spiritual: 47,
  lifeBalance: 97,
  health: 87,
  finance: 77,
  family: 67,
  work: 57,
  pleasure: 47,
  personalGrowth: 97,
  respectMyself: 87,
  selfAwarness: 77,
  personalDevelopment: 67,
  selfActualisation: 57,
  optimism: 47,
  professionalGrowth: 97,
  learningTraining: 87,
  developmentOpportunities: 77,
  careerAdvancement: 67,
  jobSuitability: 57,
  companyFit: 47,
  purpose: 97,
  personal: 87,
  relationships: 77,
  organisation: 67,
  communityCountry: 57,
  planet: 47,
  happiness: 97,
  lifeQualitySatisfaction: 87,
  liveOutsideWork: 77,
  lovedAndLoving: 67,
  happyFulfiled: 57,
  noRegrets: 47,
  jobSatisfaction: 97,
  workLifeBalance: 87,
  workStress: 77,
  lifeForce: 67,
  growthPotential: 95,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
  },
  appBar: {
    position: "absolute",
    marginLeft: drawerWidth,
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  grow: {
    flexGrow: 1,
  },
  headerTitle: {
    fontSize: "10px",
    lineHeight: "21px",
    background: "url(/assets/img/q-logo-w.png) no-repeat left center",
    padding: "0 0 0 138px",
  },
  navIconHide: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  floatRight: { float: "right" },
  floatLeft: { float: "left" },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up("md")]: {
      position: "relative",
    },
  },
  content: {
    flexGrow: 1,
    /* backgroundColor: theme.palette.background.default, */
    padding: theme.spacing(3),
  },
});

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      mobileOpen: false,
      showUserDetails: false,
      userId: null,
      location: "/",
      inviteId: "",
      reportData: reportDumyData,
    };
  }
  handleDrawerToggle = () => {
    this.setState((state) => ({ mobileOpen: !state.mobileOpen }));
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  showUserResult = (e) => {
    console.log(e);
  };

  setLocation = (location) => {
    if (this.state.location !== location) {
      this.setState({ location: location });
    }
  };

  getReport = async (id) => {
    const sortkey = "some-key";
    /* const url =
      "https://3skcthz6o5.execute-api.eu-central-1.amazonaws.com/dev/response/getStaticReport"; */
    const url =
      "https://gqfbllwv55.execute-api.eu-central-1.amazonaws.com/staging/staticReport/get";
    const report = await axios
      .get(url, {
        params: { id: id, sortkey: sortkey },
      })
      .then((response) => {
        console.log("ha ha ha====== ", response.data);
        if (
          response &&
          response.data &&
          response.data.Items &&
          response.data.Items[0]
        ) {
          this.setState({ reportData: response.data.Items[0] });
        } else {
          this.setState({ reportData: reportDumyData });
        }
      });
  };

  render() {
    const { classes } = this.props;
    const { anchor, open } = this.state;

    return (
      <div>
        <div className={classes.root}>
          <AppBar>
            <Toolbar disableGutters={!open}>
              <div className="headerContent">
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.handleDrawerToggle}
                  className={classes.navIconHide}
                >
                  <MenuIcon />
                </IconButton>
                <Typography
                  variant="subtitle1"
                  color="inherit"
                  noWrap
                  className={[classes.headerTitle, classes.floatLeft]}
                >
                  5 Pulses
                </Typography>
                <Typography
                  variant="inherit"
                  color="inherit"
                  align="right"
                  className={classes.floatRight}
                >
                  Personal code:{this.state.reportData.inviteId}
                </Typography>
              </div>
            </Toolbar>
          </AppBar>
          <main
            className={classNames(
              classes.content,
              classes[`content-${anchor}`],
              {
                [classes.contentShift]: open,
                [classes[`contentShift-${anchor}`]]: open,
              }
            )}
          >
            <div className={classes.toolbar} />
            <div className="mainContent">
              <Routes
                getReport={this.getReport}
                reportData={this.state.reportData}
              />
            </div>
          </main>
        </div>
        <footer>
          <div className="footerContent">
            <Grid container spacing={16}>
              <Grid item xs={4}>
                <p>
                  <b>Privacy Policy</b>
                </p>

                <p>
                  The company Third Millennium Knowledge commits to protecting
                  and respecting your privacy, as well as General Data
                  Protection Regulation (GDPR) that took effect on May 25, 2018.
                </p>

                <p>
                  In addition, before taking the survey and our related
                  services, or otherwise submitting personal information to us,
                  you gave us the consent that we may collect, use and disclose
                  such personal information and related results in accordance
                  with our privacy policy and as permitted or required by law.
                </p>
                <p>
                  © 2019 Quintaum, Third Millenium Knowledge. All rights
                  reserved.
                </p>
              </Grid>
              <Grid item xs={4}>
                <p>
                  More info: to understand 5 Pulses please see introduction
                  video and Pulses explanation on{" "}
                  <a href="http://www.quintaum.com/5pulses/discover/">
                    http://www.quintaum.com/5pulses/discover/
                  </a>{" "}
                  or read some Good reads on{" "}
                  <a href="http://www.quintaum.com/5pulses/good-reads/">
                    http://www.quintaum.com/5pulses/good-reads/
                  </a>
                </p>

                <p>
                  More questions? We are here for you, please just contact us{" "}
                  <a href="mailto:hello@quintaum.com">hello@quintaum.com</a>
                </p>
                <p>
                  <br />
                </p>
                <h4>
                  Work smart, not hard and May the Life Force be with you!
                </h4>
              </Grid>
              <Grid item xs={4}>
                <p>
                  <b>The story behind Quintaum</b> Our story is hidden in
                  Quintaum name. The word "quintessential" represents the most
                  perfect, the superior. The term "AUM" refers to the "Assets
                  Under Management" in finance and in Hindu "AUM" represents the
                  sound of infinitive and of the entire Universe. To reach this
                  state you need to balance Earth, Air, Fire and Water and a
                  putative fifth element - “quinta essentia”, that was believed
                  to be superior, and so, "quintessential."
                </p>

                <p>
                  In other words, Quintaum means that the superior, the best,
                  the quintessential asset of all and any organization,
                  community and nation are – The People. Therefore, we invite
                  you to increase the awareness of your Life Pulses and to take
                  back the control of your life!
                </p>
              </Grid>
            </Grid>
          </div>
        </footer>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Dashboard);
