import React from "react";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "left",
    color: theme.palette.text.secondary,
  },
  paperCenter: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
});

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invideId: null,
    };
  }

  handleInputChange = (e, inputType) => {
    this.setState({ invideId: e.target.value });
  };

  render() {
    return (
      <div>
        <div>
          <h1>5 pulses reports</h1>
          <p>Please enter your Invite ID</p>
          <div>
            <TextField
              className="reportTextFieldLong"
              label="Report ID"
              value=""
              onChange={(e) => this.handleInputChange(e, "inviteId")}
            />
          </div>
          <div>
            <br />
            <Button
              variant="contained"
              disabled={this.state.fieldsEmpty}
              color="primary"
              size="small"
              onClick={this.displayReport}
            >
              Enter
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Home);
