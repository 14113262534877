import React from "react";

import Grid from "@material-ui/core/Grid";

import Chart from "react-apexcharts";

import { levels } from "../reports-common-utils/constants";

import {
  Divider,
  IconTitleAndSubtittle,
  IconAndTitle,
  CenterElements,
  getOptionsForValue,
  ScoreAndExplanation
} from "../reports-common-utils/";

import { standardCircle } from "../../common/chartOptions";

const BaseReport = function(props) {
  const { classes, reportData } = props;
  return (
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <h1>Congratulations! That’s your 5 Pulses snapshot:</h1>
      </Grid>
      <Grid item xs={5}>
        <IconAndTitle
          icon={"energy"}
          lightDesc={"1st pulse"}
          title={"My energy"}
          subTitle={"Am I enough energized to achieving my best-self?"}
        />
      </Grid>
      <Grid item xs={7}>
        <p>
          The first Pulse shows how much energized you are in this moment. Did
          you know that to bring your best energy to work and life you have to
          be physically energized, mentally focused, emotionally balanced, wired
          to your soul, and spiritually aligned?
        </p>
      </Grid>
      <Divider height={16} />
      {/* break */}
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <div className={classes.boxItem}>
            <div className={classes.boxItemInner}>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <span
                    className={[classes.lightText, classes.textCenter].join(
                      " "
                    )}
                  >
                    Your current energies to help you increase your levels of
                    energy in all five dimensions:
                  </span>
                </Grid>
              </Grid>
              <Divider height={16} />
              <Grid container spacing={8}>
                <Grid item xs={3}>
                  <CenterElements>
                    <div className={classes.pullUp}>
                      <Chart
                        options={getOptionsForValue(reportData.energy)}
                        series={[reportData.energy]}
                        type="radialBar"
                        width={300}
                        height={300}
                        colors={["#68f989"]}
                        fill={{
                          colors: ["#68f989", "#68f989", "#68f989"]
                        }}
                      />
                    </div>
                  </CenterElements>
                  <ScoreAndExplanation
                    pulse="energy"
                    score={reportData.energy}
                  />
                </Grid>
                <Grid item xs={9}>
                  <div className="box-container">
                    <div className="box-item">
                      <IconTitleAndSubtittle
                        icon={"physical"}
                        title={"Physical"}
                        subTitle={"Supports your health and wellbeing."}
                      />
                      <CenterElements>
                        <Chart
                          options={standardCircle}
                          series={[reportData.physical]}
                          type="radialBar"
                          width={150}
                          height={150}
                        />
                      </CenterElements>
                    </div>
                    <div className="box-item">
                      <IconTitleAndSubtittle
                        icon={"mental"}
                        title={"Mental"}
                        subTitle={"Increases your focus and inner peace."}
                      />
                      <CenterElements>
                        <Chart
                          options={standardCircle}
                          series={[reportData.mental]}
                          type="radialBar"
                          width={150}
                          height={150}
                        />
                      </CenterElements>
                    </div>
                    <div className="box-item">
                      <IconTitleAndSubtittle
                        icon={"emotional"}
                        title={"Emotional"}
                        subTitle={"Balances your sentiments and relationships."}
                      />
                      <CenterElements>
                        <Chart
                          options={standardCircle}
                          series={[reportData.emotional]}
                          type="radialBar"
                          width={150}
                          height={150}
                        />
                      </CenterElements>
                    </div>
                    <div className="box-item">
                      <IconTitleAndSubtittle
                        icon={"soul"}
                        title={"Soul"}
                        subTitle={"Boosts your creativity and pleasure."}
                      />
                      <CenterElements>
                        <Chart
                          options={standardCircle}
                          series={[reportData.soul]}
                          type="radialBar"
                          width={150}
                          height={150}
                        />
                      </CenterElements>
                    </div>
                    <div className="box-item">
                      <IconTitleAndSubtittle
                        icon={"spiritual"}
                        title={"Spiritual"}
                        subTitle={"Ignite your life meaning and purpose."}
                      />
                      <CenterElements>
                        <Chart
                          options={standardCircle}
                          series={[reportData.spiritual]}
                          type="radialBar"
                          width={150}
                          height={150}
                        />
                      </CenterElements>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
      <Divider height={8} />

      {/* break */}
      <Grid container spacing={8}>
        <Grid item xs={4}>
          <div className={classes.boxItem}>
            <div className={classes.boxItemInner}>
              <IconAndTitle
                icon={"life-balance"}
                lightDesc={"2nd pulse"}
                title={"My life balance"}
                subTitle={"Am I living in balance?"}
              />
              <p>
                The second Pulse is about how successful you are balancing the
                most important aspects of your life, from health to work. Life
                balance needs continuous maintenance and effort and this is why
                a snapshot of your current Life balance is so important.
              </p>
              <CenterElements>
                <Chart
                  options={getOptionsForValue(reportData.lifeBalance)}
                  series={[reportData.lifeBalance]}
                  type="radialBar"
                  width={300}
                  height={300}
                />
              </CenterElements>
              <ScoreAndExplanation
                pulse="lifeBalance"
                score={reportData.lifeBalance}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.boxItem}>
            <div className={classes.boxItemInner}>
              <IconAndTitle
                icon={"growth-potential"}
                lightDesc={"3rd pulse"}
                title={"My growth potential"}
                subTitle={"Am I living my potentials?"}
              />
              <p>
                The third Pulse taps into your Growth potential to better
                understand what limits you and how to move forward in the
                future, both in terms of your professional and personal
                development path, from self-awareness, to optimism.
              </p>
              <CenterElements>
                <Chart
                  options={getOptionsForValue(reportData.growthPotential)}
                  series={[reportData.growthPotential]}
                  type="radialBar"
                  width={300}
                  height={300}
                />
              </CenterElements>
              <ScoreAndExplanation
                pulse="growth"
                score={reportData.growthPotential}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.boxItem}>
            <div className={classes.boxItemInner}>
              <IconAndTitle
                icon={"purpose"}
                lightDesc={"4th pulse"}
                title={"My purpose"}
                subTitle={"Am I living meaningful life?"}
              />
              <p>
                The fourth Pulse explores how much deeply meaningful life you
                live and whether you are doing what you really want to do in
                your life, regardless if this is related to your personal life,
                your family, organization, community or to the planet Earth.
              </p>
              <CenterElements>
                <Chart
                  options={getOptionsForValue(reportData.purpose)}
                  series={[reportData.purpose]}
                  type="radialBar"
                  width={300}
                  height={300}
                />
              </CenterElements>
              <ScoreAndExplanation pulse="purpose" score={reportData.purpose} />
            </div>
          </div>
        </Grid>
      </Grid>
      <Divider height={8} />
      {/* break */}
      <Grid container spacing={8}>
        <Grid item xs={4}>
          <div className={classes.boxItem}>
            <div className={classes.boxItemInner}>
              <IconAndTitle
                icon={"happiness"}
                lightDesc={"5th pulse"}
                title={"My happiness"}
                subTitle={"Am I fully living my life?"}
              />
              <p>
                It’s important to be reminded that whatever stage we are at in
                life, there is no need for regret. When it comes to making
                changes, be at peace with the past and remember that each moment
                is a new choice. The fifth pulse will help you overcome your
                fears and become a more courageous “version” of yourself.
              </p>
              <CenterElements>
                <Chart
                  options={getOptionsForValue(reportData.happiness)}
                  series={[reportData.happiness]}
                  type="radialBar"
                  width={300}
                  height={300}
                />
              </CenterElements>
              <ScoreAndExplanation
                pulse="happiness"
                score={reportData.happiness}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.boxItem}>
            <div className={classes.boxItemInner}>
              <IconAndTitle
                icon={"life-force"}
                lightDesc={"Special pulse"}
                title={"MY LIFE FORCE"}
                subTitle={"Am I thriving and evolving in my Life?"}
              />
              <p>
                “Quintaum LIFE FORCE” is not a Pulse but rather a hint
                reflecting the engagement of all energies in all dimensions of
                your life, your capacity to thrive and evolve on the long run.
                Although it may sound fun, this signal is here to significantly
                boost your awareness about yourself and to help you become much
                more than you’ve ever thought was even possible!
              </p>
              <Divider height={20} />
              <center>
                <h4>YOU'RE A</h4>
                <h2>{reportData.level && levels[reportData.level].level}</h2>
                <p>
                  {reportData.level && levels[reportData.level].explanation}
                </p>
              </center>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.boxItem}>
            <div className={classes.boxItemInner}>
              <h2 className={classes.allCapsTitle}>Life is so awesome</h2>
              <p className={classes.fontSizeNormal}>
                Life is so awesome but with everyday stress we usually forget
                how important are our Health, Love, Family, Joy, our Talents,
                and living truly Meaningful Life.
              </p>
              <p>
                This personalized 5 Pulses Snapshot reports is “a kind reminder”
                reflecting five the most important Pulses of your Life. Don’t be
                too much stressed if there might be some red flags in the
                reports. We cannot balance and have everything in every moment
                of our Life. However, we should be aware of our vulnerabilities
                to be able to improve, balance, and take back the control of our
                Life!
              </p>
              <p>
                <b>Note:</b> Subject to selected corporate package you might
                receive more detailed report after the Quintaum diagnostic is
                finished in your Organization.
              </p>
              <p>
                <b>Disclaimer:</b> The results are based on Quintaum Diagnostic,
                however the results accuracy and relevance is subject of the
                entry information submitted from your side during the survey.
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
      {/* break */}

      <Divider height={40} />
    </Grid>
  );
};

export default BaseReport;
