import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";

import Chart from "react-apexcharts";

import { levels } from "../reports-common-utils/constants";

import {
  Divider,
  IconTitleAndSubtittle,
  TitleSubtitleAndChart,
  TitleSubtitleAndSmiley,
  IconAndTitle,
  IconHeader,
  IconLeftTitle,
  CenterElements,
  getOptionsForValue,
  Score,
  SmileyVertical,
  SmileyHorizontal,
  getScoreLabelWorkLife,
  getScoreLabelJobSatisfaction,
  getScoreLabelWorkStress,
  getExplanationForPulseAndScore,
  getLabelforPulseAndLevel,
} from "../reports-common-utils/";

import { standardCircle } from "../../common/chartOptions";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const BaseReport = function (props) {
  const { classes, reportData, copy } = props;
  const legendItem = {
    flex: 1,
  };
  console.log("copy ===", copy);
  //console.log("smiley", reportData.respectMyself);
  return (
    <div>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <CenterElements>
            <h1>5 Življenjskih pulzev</h1>
          </CenterElements>
        </Grid>
        <Grid item xs={12}>
          <div className="box-container">
            <div className="box-item">
              <IconHeader icon={"energy"} title={"Osebna energija"} />
              <CenterElements>
                <div className={classes.pullUp}>
                  <Chart
                    options={getOptionsForValue(reportData.energy)}
                    series={[90]}
                    type="radialBar"
                    width={220}
                    height={250}
                    colors={["#68f989"]}
                    fill={{
                      colors: ["#68f989", "#68f989", "#68f989"],
                    }}
                  />
                </div>
              </CenterElements>
              <Score pulse="energy" score={90} />
              <TitleSubtitleAndChart
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                options={standardCircle}
                series={[reportData.mental]}
                type="radialBar"
                width={70}
                height={110}
              />
              <TitleSubtitleAndChart
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                options={standardCircle}
                series={[reportData.mental]}
                type="radialBar"
                width={70}
                height={110}
              />
              <TitleSubtitleAndChart
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                options={standardCircle}
                series={[reportData.mental]}
                type="radialBar"
                width={70}
                height={110}
              />
              <TitleSubtitleAndChart
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                options={standardCircle}
                series={[reportData.mental]}
                type="radialBar"
                width={70}
                height={110}
              />
              <TitleSubtitleAndChart
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                options={standardCircle}
                series={[reportData.mental]}
                type="radialBar"
                width={70}
                height={110}
              />
            </div>
            <div className="box-item">
              <IconHeader
                icon={"life-balance"}
                title={"Življensko ravnotežje"}
              />
              <CenterElements>
                <div className={classes.pullUp}>
                  <Chart
                    options={getOptionsForValue(reportData.energy)}
                    series={[reportData.energy]}
                    type="radialBar"
                    width={220}
                    height={250}
                    colors={["#68f989"]}
                    fill={{
                      colors: ["#68f989", "#68f989", "#68f989"],
                    }}
                  />
                </div>
              </CenterElements>
              <Score pulse="energy" score={reportData.energy} />
              <TitleSubtitleAndChart
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                options={standardCircle}
                series={[reportData.mental]}
                type="radialBar"
                width={80}
                height={110}
              />
              <TitleSubtitleAndChart
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                options={standardCircle}
                series={[reportData.mental]}
                type="radialBar"
                width={80}
                height={110}
              />
              <TitleSubtitleAndChart
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                options={standardCircle}
                series={[reportData.mental]}
                type="radialBar"
                width={80}
                height={110}
              />
              <TitleSubtitleAndChart
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                options={standardCircle}
                series={[reportData.mental]}
                type="radialBar"
                width={80}
                height={110}
              />
              <TitleSubtitleAndChart
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                options={standardCircle}
                series={[reportData.mental]}
                type="radialBar"
                width={80}
                height={110}
              />
            </div>
            <div className="box-item">
              <IconHeader icon={"growth-potential"} title={"Osebna rast"} />
              <CenterElements>
                <div className={classes.pullUp}>
                  <Chart
                    options={getOptionsForValue(reportData.energy)}
                    series={[reportData.energy]}
                    type="radialBar"
                    width={220}
                    height={250}
                    colors={["#68f989"]}
                    fill={{
                      colors: ["#68f989", "#68f989", "#68f989"],
                    }}
                  />
                </div>
              </CenterElements>
              <Score pulse="energy" score={reportData.energy} />
              <TitleSubtitleAndSmiley
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                score={60}
              />
              <TitleSubtitleAndSmiley
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                score={reportData.respectMyself}
              />
              <TitleSubtitleAndSmiley
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                score={80}
              />
              <TitleSubtitleAndSmiley
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                score={reportData.respectMyself}
              />
              <TitleSubtitleAndSmiley
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                score={90}
              />
            </div>
            <div className="box-item">
              <IconHeader icon={"happiness"} title={"Srečno življenje"} />
              <CenterElements>
                <div className={classes.pullUp}>
                  <Chart
                    options={getOptionsForValue(reportData.energy)}
                    series={[90]}
                    type="radialBar"
                    width={220}
                    height={250}
                    colors={["#68f989"]}
                    fill={{
                      colors: ["#68f989", "#68f989", "#68f989"],
                    }}
                  />
                </div>
              </CenterElements>
              <Score pulse="energy" score={90} />
              <TitleSubtitleAndSmiley
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                score={90}
              />
              <TitleSubtitleAndSmiley
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                score={reportData.respectMyself}
              />
              <TitleSubtitleAndSmiley
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                score={80}
              />
              <TitleSubtitleAndSmiley
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                score={reportData.respectMyself}
              />
              <TitleSubtitleAndSmiley
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                score={60}
              />
            </div>
            <div className="box-item">
              <IconHeader icon={"purpose"} title={"Življensko poslanstvo"} />
              <CenterElements>
                <div className={classes.pullUp}>
                  <Chart
                    options={getOptionsForValue(reportData.energy)}
                    series={[reportData.energy]}
                    type="radialBar"
                    width={220}
                    height={250}
                    colors={["#68f989"]}
                    fill={{
                      colors: ["#68f989", "#68f989", "#68f989"],
                    }}
                  />
                </div>
              </CenterElements>
              <Score pulse="energy" score={reportData.energy} />
              <TitleSubtitleAndSmiley
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                score={60}
              />
              <TitleSubtitleAndSmiley
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                score={reportData.respectMyself}
              />
              <TitleSubtitleAndSmiley
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                score={80}
              />
              <TitleSubtitleAndSmiley
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                score={reportData.respectMyself}
              />
              <TitleSubtitleAndSmiley
                title={"Mental"}
                subTitle={"Increases your focus and inner peace."}
                score={90}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <Divider height={16} />
      <Grid container spacing={8}>
        <Grid item xs={3}>
          <h3>Signali stresa</h3>
          <SmileyHorizontal
            size={"large"}
            title={"Respect myself"}
            description={"Pozor"}
            score={90}
          />
          <SmileyHorizontal
            size={"large"}
            title={"Respect myself"}
            description={"Pozor"}
            score={reportData.respectMyself}
          />
          <SmileyHorizontal
            size={"large"}
            title={"Respect myself"}
            description={"Pozor"}
            score={80}
          />
          <SmileyHorizontal
            size={"large"}
            title={"Respect myself"}
            description={"Pozor"}
            score={reportData.respectMyself}
          />
          <SmileyHorizontal
            size={"large"}
            title={"Respect myself"}
            description={"Pozor"}
            score={60}
          />
        </Grid>
        <Grid item xs={3}>
          <h3>Življenska Sila</h3>
        </Grid>
        <Grid item xs={3}>
          <h3>Karierni signali</h3>
          <SmileyHorizontal
            size={"large"}
            title={"Respect myself"}
            description={"Pozor"}
            score={60}
          />
          <SmileyHorizontal
            size={"large"}
            title={"Respect myself"}
            description={"Pozor"}
            score={reportData.respectMyself}
          />
          <SmileyHorizontal
            size={"large"}
            title={"Respect myself"}
            description={"Pozor"}
            score={80}
          />
          <SmileyHorizontal
            size={"large"}
            title={"Respect myself"}
            description={"Pozor"}
            score={reportData.respectMyself}
          />
          <SmileyHorizontal
            size={"large"}
            title={"Respect myself"}
            description={"Pozor"}
            score={90}
          />
        </Grid>
        <Grid item xs={3}>
          <h3>Profesionalni razvoj</h3>
          <SmileyHorizontal
            size={"large"}
            title={"Respect myself"}
            description={"Pozor"}
            score={90}
          />
          <SmileyHorizontal
            size={"large"}
            title={"Respect myself"}
            description={"Pozor"}
            score={reportData.respectMyself}
          />
          <SmileyHorizontal
            size={"large"}
            title={"Respect myself"}
            description={"Pozor"}
            score={80}
          />
          <SmileyHorizontal
            size={"large"}
            title={"Respect myself"}
            description={"Pozor"}
            score={reportData.respectMyself}
          />
          <SmileyHorizontal
            size={"large"}
            title={"Respect myself"}
            description={"Pozor"}
            score={60}
          />
        </Grid>
      </Grid>
      <Divider height={16} />
    </div>
  );
};

export default BaseReport;
