import React from "react";
import { Switch, Route } from "react-router";

import Home from "../components/Home";
import StaticReport from "../components/StaticReport";
import { fivePulsesCopy } from "../components/reports/reportsConfig";
import My5pulsesReport from "../components/reports/5pulsesReport";
import Slo5pulsesReport from "../components/reports/Slo5pulsesReport";
import My5pulsesSnapshotReport from "../components/reports/5pulsesSnapshotReport";

const Routes = (props) => {
  let getReport = props.getReport;
  let reportData = props.reportData;

  console.log("route == ", props);
  return (
    <Switch>
      <Route path="/" exact render={(props) => <Home />} />
      <Route
        path="/report/:reportId"
        exact
        render={(props) => (
          <StaticReport
            reportId={props.match.params.reportId}
            getReport={getReport}
            reportData={reportData}
            ReportComponent={My5pulsesSnapshotReport}
          />
        )}
      />
      <Route
        path="/5pulsesSnapshotReport/:reportId"
        exact
        render={(props) => (
          <StaticReport
            reportId={props.match.params.reportId}
            getReport={getReport}
            reportData={reportData}
            ReportComponent={My5pulsesSnapshotReport}
          />
        )}
      />
      <Route
        path="/5pulsesReport/:reportId"
        exact
        render={(props) => (
          <StaticReport
            reportId={props.match.params.reportId}
            getReport={getReport}
            reportData={reportData}
            ReportComponent={My5pulsesReport}
          />
        )}
      />
      <Route
        path="/Rezultati/:reportId"
        exact
        render={(props) => (
          <StaticReport
            reportId={props.match.params.reportId}
            getReport={getReport}
            reportData={reportData}
            ReportComponent={Slo5pulsesReport}
            copy={fivePulsesCopy.slo}
          />
        )}
      />
    </Switch>
  );
};

export default Routes;
