export const multiple = {
  chart: {
    height: 350,
    type: "radialBar"
  },
  plotOptions: {
    radialBar: {
      dataLabels: {
        name: {
          fontSize: "22px"
        },
        value: {
          fontSize: "16px"
        },
        total: {
          show: true,
          label: "Total",
          formatter: function(w) {
            // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
            return 249;
          }
        }
      }
    }
  },
  series: [44, 55, 67, 83],
  labels: ["Apples", "Oranges", "Bananas", "Berries"]
};

export const options1 = {
  chart: {
    height: 220,
    type: "radialBar"
  },
  // this is the base colour
  colors: ["#485dfc"],
  plotOptions: {
    radialBar: {
      startAngle: -120,
      endAngle: 120,
      dataLabels: {
        name: {
          fontSize: "16px",
          color: undefined,
          offsetY: 120
        },
        value: {
          offsetY: -10,
          fontSize: "22px",
          color: undefined,
          formatter: function(val) {
            return val + "%";
          }
        }
      }
    }
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      type: "horizontal",
      shadeIntensity: 0.5,
      //gradientFromColors: ["#dc5a32"],
      // this is the gradient colour
      gradientToColors: ["#d30000"],
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100]
    }
  },
  stroke: {
    //dashArray: 4
  },
  series: [67],
  labels: ["Median Ratio"]
};
export const options2 = {
  chart: {
    height: 350,
    type: "radialBar",
    toolbar: {
      show: true
    }
  },
  plotOptions: {
    radialBar: {
      startAngle: -135,
      endAngle: 135,
      hollow: {
        margin: 0,
        size: "70%",
        background: "#fff",
        image: undefined,
        imageOffsetX: 0,
        imageOffsetY: 0,
        position: "front",
        dropShadow: {
          enabled: true,
          top: 3,
          left: 0,
          blur: 4,
          opacity: 0.24
        }
      },
      track: {
        background: "#fff",
        strokeWidth: "67%",
        margin: 0, // margin is in pixels
        dropShadow: {
          enabled: true,
          top: -3,
          left: 0,
          blur: 4,
          opacity: 0.35
        }
      },

      dataLabels: {
        showOn: "always",
        name: {
          offsetY: -10,
          show: true,
          color: "#888",
          fontSize: "17px"
        },
        value: {
          formatter: function(val) {
            return parseInt(val);
          },
          color: "#111",
          fontSize: "36px",
          show: true
        }
      }
    }
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "dark",
      type: "horizontal",
      shadeIntensity: 0.5,
      //gradientToColors: ["#ABE5A1"],
      gradientToColors: ["#000"],
      inverseColors: true,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100]
    }
  },
  stroke: {
    lineCap: "round"
  },
  labels: ["Percent"]
};

export const standardCircle = {
  chart: {
    height: 250,
    type: "radialBar"
  },
  plotOptions: {
    radialBar: {
      hollow: {
        size: "60%"
      },
      dataLabels: {
        name: {
          show: false
        },
        value: {
          offsetY: 8,
          fontSize: "16px"
        }
      }
    }
  },
  stroke: {
    lineCap: "round"
  },
  colors: ["#1127ff", "#0084ff", "#39539E", "#0077B5"],
  series: [70],
  labels: ["Percent"]
};

export const halfCircle = {
  chart: {
    type: "radialBar"
  },
  plotOptions: {
    radialBar: {
      hollow: {
        size: "60%"
      },
      startAngle: -90,
      endAngle: 90,
      track: {
        background: "#e7e7e7",
        strokeWidth: "97%",
        margin: 5 // margin is in pixels
      },
      dataLabels: {
        name: {
          show: true,
          fontSize: "28px",
          color: "#000f30"
        },
        value: {
          offsetY: 15,
          fontSize: "16px",
          color: "#000f30"
        }
      }
    }
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      shadeIntensity: 0.4,
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 50, 53, 91]
    }
  },
  stroke: {
    lineCap: "round"
  },
  series: [76],
  labels: ["High"]
};

export const halfCircleDarkGreen = {
  chart: {
    type: "radialBar"
  },
  plotOptions: {
    radialBar: {
      hollow: {
        size: "60%"
      },
      startAngle: -90,
      endAngle: 90,
      track: {
        background: "#e7e7e7",
        strokeWidth: "97%",
        margin: 5 // margin is in pixels
      },
      dataLabels: {
        name: {
          show: false,
          fontSize: "24px",
          color: "#000f30"
        },
        value: {
          offsetY: -15,
          fontSize: "16px",
          color: "#000f30"
        }
      }
    }
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      shadeIntensity: 0.4,
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 50, 53, 91],
      gradientToColors: ["#2e940e"]
    }
  },
  stroke: {
    lineCap: "round"
  },
  series: [76],
  labels: ["High"],
  colors: ["#68f989"]
};

export const halfCircleGreen = {
  chart: {
    type: "radialBar"
  },
  plotOptions: {
    radialBar: {
      hollow: {
        size: "60%"
      },
      startAngle: -90,
      endAngle: 90,
      track: {
        background: "#e7e7e7",
        strokeWidth: "97%",
        margin: 5 // margin is in pixels
      },
      dataLabels: {
        name: {
          show: false,
          fontSize: "24px",
          color: "#000f30"
        },
        value: {
          offsetY: -15,
          fontSize: "16px",
          color: "#000f30"
        }
      }
    }
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      shadeIntensity: 0.4,
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 50, 53, 91],
      gradientToColors: ["#68f989"]
    }
  },
  stroke: {
    lineCap: "round"
  },
  series: [76],
  labels: ["High"],
  colors: ["#c8f968"]
};

export const halfCircleRed = {
  chart: {
    type: "radialBar"
  },
  plotOptions: {
    radialBar: {
      hollow: {
        size: "60%"
      },
      startAngle: -90,
      endAngle: 90,
      track: {
        background: "#e7e7e7",
        strokeWidth: "97%",
        margin: 5 // margin is in pixels
      },
      dataLabels: {
        name: {
          show: false,
          fontSize: "24px",
          color: "#000f30"
        },
        value: {
          offsetY: -15,
          fontSize: "20px",
          color: "#000f30"
        }
      }
    }
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      shadeIntensity: 0.4,
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 50, 53, 91],
      gradientToColors: ["#940e0e"]
    }
  },
  stroke: {
    lineCap: "round"
  },
  series: [76],
  labels: ["Low"],
  colors: ["#f96868"]
};

export const halfCircleOrange = {
  chart: {
    type: "radialBar"
  },
  plotOptions: {
    radialBar: {
      hollow: {
        size: "60%"
      },
      startAngle: -90,
      endAngle: 90,
      track: {
        background: "#e7e7e7",
        strokeWidth: "97%",
        margin: 5 // margin is in pixels
      },
      dataLabels: {
        name: {
          show: false,
          fontSize: "24px",
          color: "#000f30"
        },
        value: {
          offsetY: -15,
          fontSize: "16px",
          color: "#000f30"
        }
      }
    }
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      shadeIntensity: 0.4,
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 50, 53, 91],
      gradientToColors: ["#e47c27"]
    }
  },
  stroke: {
    lineCap: "round"
  },
  series: [76],
  labels: ["Medium low"],
  colors: ["#facd5b"]
};

export const halfCircleYellow = {
  chart: {
    type: "radialBar"
  },
  plotOptions: {
    radialBar: {
      hollow: {
        size: "60%"
      },
      startAngle: -90,
      endAngle: 90,
      track: {
        background: "#e7e7e7",
        strokeWidth: "97%",
        margin: 5 // margin is in pixels
      },
      dataLabels: {
        name: {
          show: false,
          fontSize: "24px",
          color: "#000f30"
        },
        value: {
          offsetY: -15,
          fontSize: "16px",
          color: "#000f30"
        }
      }
    }
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      shadeIntensity: 0.4,
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 50, 53, 91],
      gradientToColors: ["#facd5b"]
    }
  },
  stroke: {
    lineCap: "round"
  },
  series: [76],
  labels: ["Medium"],
  colors: ["#e4dc27"]
};
