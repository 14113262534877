import React from "react";
import { scoreAndExplanationMatrix } from "./constants";
import Grid from "@material-ui/core/Grid";
import Chart from "react-apexcharts";

import {
  halfCircleDarkGreen,
  halfCircleGreen,
  halfCircleRed,
  halfCircleOrange,
  halfCircleYellow,
} from "../../common/chartOptions";

export const CustomIcon = function (props) {
  const center = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "10px",
    paddingRight: "10px",
  };
  props.fullHeight && (center.height = "100%");

  const path = `/assets/img/${props.icon}.png`;
  return (
    <div style={center}>
      <img src={path} />
    </div>
  );
};

export const CenterIcon = function (props) {
  const center = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "10px",
    paddingRight: "10px",
  };
  props.fullHeight && (center.height = "50%");

  const path = `/assets/img/${props.icon}.png`;
  return (
    <div style={center}>
      <img src={path} />
    </div>
  );
};

export const Divider = function (props) {
  return (
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <div style={{ height: props.height }}> </div>
      </Grid>
    </Grid>
  );
};

export const IconTitleAndSubtittle = function (props) {
  const container = {
    textAlign: "center",
  };
  const squeezedTitle = {
    fontSize: "20px",
    fontWeight: 600,
    margin: "6px 0 2px 0",
  };
  const subTitle = { fontSize: "14px" };

  return (
    <div style={container}>
      <CustomIcon icon={props.icon} fullHeight={false} />
      <h2 style={squeezedTitle}>{props.title}</h2>
      <span style={subTitle}>{props.subTitle}</span>
    </div>
  );
};

export const TitleSubtitleAndChart = function (props) {
  const container = {
    textAlign: "left",
  };
  const squeezedTitle = {
    fontSize: "20px",
    fontWeight: 600,
    margin: "6px 0 2px 0",
  };
  const subTitle = { fontSize: "14px" };

  return (
    <div style={container}>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <h2 style={squeezedTitle}>{props.title}</h2>
          <span style={subTitle}>{props.subTitle}</span>
        </Grid>
        <Grid item xs={4}>
          <Chart
            options={props.options}
            series={props.series}
            type={props.type}
            width={props.width}
            height={props.height}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export const TitleSubtitleAndSmiley = function (props) {
  const { score } = props;
  let icon;

  if (score) {
    icon = getScoreSmiley(score);
  } else if (level) {
    icon = getLevelSmiley(level);
  } else {
    return <div />;
  }
  const container = {
    textAlign: "left",
  };
  const squeezedTitle = {
    fontSize: "20px",
    fontWeight: 600,
    margin: "6px 0 2px 0",
  };
  const subTitle = { fontSize: "14px" };
  const smiley = { padding: "25px" };

  return (
    <div style={container}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <h2 style={squeezedTitle}>{props.title}</h2>
          <span style={subTitle}>{props.subTitle}</span>
        </Grid>
        <Grid item xs={4} style={smiley}>
          <CustomIcon icon={icon} fullHeight={false} />
        </Grid>
      </Grid>
    </div>
  );
};

export const IconAndTitle = function (props) {
  const lightText = { fontSize: "12px", color: "#909196" };
  const squeezedTitle = {
    fontSize: "24px",
    fontWeight: 600,
    margin: "0 0 0 0",
    textTransform: "uppercase",
  };
  const subTitle = { fontSize: "16px" };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: "0 0 80px" }}>
        <CustomIcon icon={props.icon} fullHeight={true} />
      </div>
      <div style={{ flex: "1" }}>
        <span style={lightText}>{props.lightDesc}</span>
        <h2 style={squeezedTitle}>{props.title}</h2>
        <span style={subTitle}>{props.subTitle}</span>
      </div>
    </div>
  );
};

export const IconHeader = function (props) {
  const lightText = { fontSize: "12px", color: "#909196" };
  const squeezedTitle = {
    fontSize: "18px",
    fontWeight: 600,
    margin: "0 0 0 0",
    textTransform: "uppercase",
  };
  const subTitle = { fontSize: "16px" };

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: "0 0 80px" }}>
        <CenterIcon icon={props.icon} fullHeight={true} />
      </div>
      <div style={{ flex: "1" }}>
        <h2 style={squeezedTitle}>{props.title}</h2>
      </div>
    </div>
  );
};

export const IconLeftTitle = function (props) {
  const squeezedTitle = {
    fontSize: "20px",
    fontWeight: 600,
    margin: "0 0 10px 0",
    textTransform: "uppercase",
  };

  const subtitleTitle = {
    fontSize: "20px",
    fontWeight: 600,
    margin: "0 0 0 0",
  };

  return (
    <div style={{ display: "flex", marginTop: "20px" }}>
      <div style={{ flex: "0 0 80px" }}>
        <CustomIcon icon={props.icon} />
      </div>
      <div style={{ flex: "1" }}>
        <h2 style={squeezedTitle}>{props.title}</h2>
        <h3 style={subtitleTitle}>{props.subTitle}</h3>
      </div>
    </div>
  );
};

export const CenterElements = function (props) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {props.children}
    </div>
  );
};

export const getOptionsForValue = function (value) {
  switch (true) {
    case value < 51:
      return halfCircleRed;
    case value < 65:
      return halfCircleOrange;
    case value < 75:
      return halfCircleYellow;
    case value < 85:
      return halfCircleGreen;
    default:
      return halfCircleDarkGreen;
  }
};

export const getScoreLabel = function (value) {
  switch (true) {
    case value < 51:
      return "low";
    case value < 65:
      return "mediumLow";
    case value < 75:
      return "medium";
    case value < 85:
      return "mediumHigh";
    default:
      return "high";
  }
};

export const getScoreLabelWorkLife = function (value) {
  switch (true) {
    case value < 51:
      return "low";
    case value < 66:
      return "mediumLow";
    case value < 75:
      return "medium";
    case value < 88:
      return "mediumHigh";
    default:
      return "high";
  }
};

export const getScoreLabelJobSatisfaction = function (value) {
  switch (true) {
    case value < 51:
      return "low";
    case value < 66:
      return "mediumLow";
    case value < 75:
      return "medium";
    case value < 90:
      return "mediumHigh";
    default:
      return "high";
  }
};

export const getScoreLabelWorkStress = function (value) {
  switch (true) {
    case value < 14:
      return "high";
    case value < 24:
      return "mediumHigh";
    case value < 34:
      return "medium";
    case value < 44:
      return "mediumLow";
    default:
      return "low";
  }
};

export const getScoreSmiley = function (value) {
  switch (true) {
    case value < 51:
      return "the-worst";
    case value < 65:
      return "bad";
    case value < 75:
      return "so-so";
    case value < 85:
      return "good";
    default:
      return "the-best";
  }
};

export const getLevelSmiley = function (value) {
  switch (true) {
    case value == "low":
      return "the-worst";
    case value == "mediumLow":
      return "bad";
    case value == "medium":
      return "so-so";
    case value == "mediumHigh":
      return "good";
    default:
      return "the-best";
  }
};

export const getLabelforPulseAndLevel = function (pulse, level) {
  if (!pulse || !level || !scoreAndExplanationMatrix[pulse]) {
    return {
      label: "",
      description: "",
    };
  }

  if (!scoreAndExplanationMatrix[pulse][level]) {
    return {
      label: "",
      description: "",
    };
  }

  return scoreAndExplanationMatrix[pulse][level];
};
export const getExplanationForPulseAndScore = function (pulse, score) {
  if (!pulse || !score || !scoreAndExplanationMatrix[pulse]) {
    return {
      label: "",
      description: "",
    };
  }

  const scoreLabel = getScoreLabel(score);
  if (!scoreAndExplanationMatrix[pulse][scoreLabel]) {
    return {
      label: "",
      description: "",
    };
  }

  return scoreAndExplanationMatrix[pulse][scoreLabel];
};

export const ScoreAndExplanation = function (props) {
  const { pulse, score } = props;
  const explanationData = getExplanationForPulseAndScore(pulse, score);
  const containerStyles = {
    marginTop: "-110px",
  };
  const titleStyles = {
    textAlign: "center",
  };
  return (
    <div style={containerStyles}>
      <h4 style={titleStyles}>{explanationData.label}</h4>
      <p>{explanationData.description}</p>
    </div>
  );
};

export const Score = function (props) {
  const { pulse, score } = props;
  const explanationData = getExplanationForPulseAndScore(pulse, score);
  const containerStyles = {
    marginTop: "-20px",
  };
  const titleStyles = {
    textAlign: "center",
  };
  return (
    <div style={containerStyles}>
      <h4 style={titleStyles}>{explanationData.label}</h4>
    </div>
  );
};

export const SmileyVertical = function (props) {
  const { score, level, title, titleSecondLine, description, label } = props;
  let icon;

  if (score) {
    icon = getScoreSmiley(score);
  } else if (level) {
    icon = getLevelSmiley(level);
  } else {
    return <div />;
  }

  const containerStyles = {
    marginTop: "10px",
    textAlign: "center",
  };
  const titleStyles = {
    textAlign: "center",
    marginBottom: "5px",
  };
  const titleSecondLineStyles = {
    textAlign: "center",
    marginTop: "0",
    marginBottom: "5px",
  };
  const iconStyles = {
    flex: "0 0 80px",
  };
  const descriptionStyles = {
    fontSize: "14px",
  };

  return (
    <div style={containerStyles}>
      <h4 style={titleStyles}>{title}</h4>
      {titleSecondLine && (
        <h4 style={titleSecondLineStyles}>{titleSecondLine}</h4>
      )}
      <p style={descriptionStyles}>{description}</p>
      <div style={iconStyles}>
        <CustomIcon icon={icon} fullHeight={false} />
        {label && <p style={descriptionStyles}>{label}</p>}
      </div>
    </div>
  );
};

export const SmileyHorizontal = function (props) {
  const { score, level, title, titleSecondLine, description, label } = props;
  let icon;

  if (score) {
    icon = getScoreSmiley(score);
  } else if (level) {
    icon = getLevelSmiley(level);
  } else {
    return <div />;
  }

  const containerStyles = {
    marginTop: "10px",
    textAlign: "center",
  };
  const titleStyles = {
    textAlign: "center",
    marginBottom: "5px",
    marginTop: "0",
  };
  const titleSecondLineStyles = {
    textAlign: "center",
    marginTop: "0",
    marginBottom: "5px",
  };
  const iconStyles = {
    flex: "0 0 80px",
  };
  const descriptionStyles = {
    fontSize: "14px",
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={5}>
        <h4 style={titleStyles}>{title}</h4>
        {titleSecondLine && (
          <h4 style={titleSecondLineStyles}>{titleSecondLine}</h4>
        )}
      </Grid>
      <Grid item xs={2}>
        <div style={iconStyles}>
          <CustomIcon icon={icon} fullHeight={false} />
          {label && <p style={descriptionStyles}>{label}</p>}
        </div>
      </Grid>
      <Grid item xs={5}>
        <p style={descriptionStyles}>{description}</p>
      </Grid>
    </Grid>
  );
};
