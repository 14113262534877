export const scoreAndExplanationMatrix = {
  energy: {
    high: {
      label: "Excellent",
      description:
        "AVE Master! This suggests that your energy level is very high. It is sufficient to fully ignite your potentials, life mission and goals.",
    },
    mediumHigh: {
      label: "High",
      description:
        "Hooray! This suggests that your energy level is high, only slightly not sufficient to fully ignite your productivity, talent and life goals.",
    },
    medium: {
      label: "Satisfactory",
      description:
        "Congratulation! This suggests that your energy level is sufficient, but some obstacles are preventing you to fully ignite your healthy, happy, and productive life.",
    },
    mediumLow: {
      label: "Unsatisfactory",
      description:
        "Heads-up! This suggests that your energy level is not sufficient and that significant  obstacles are preventing you to fully ignite your healthy, happy and productive life.",
    },
    low: {
      label: "Seriously low",
      description:
        "Warning! This suggests that your energy level significantly undermines your healthy, happy and productive life. Act now!",
    },
  },
  lifeBalance: {
    high: {
      label: "Excellent",
      description:
        "Ave Master! This suggests that your skills how to balance your Life are excellent. Youl Life Balance is sufficient to live a fully prosperous Life.",
    },
    mediumHigh: {
      label: "High",
      description:
        "Hooray! This suggests that your Life balance is very high, yet you could balance it even more to live a very prosperous Life!",
    },
    medium: {
      label: "Satisfactory",
      description:
        "Congratulation! This suggests that your Life balance is sufficient, but some temporary or permanent obstacles are preventing you to live a fully prosperous Life.",
    },
    mediumLow: {
      label: "Unsatisfactory",
      description:
        "Heads-up! This suggests that your Life Balance is not sufficient and that significant  obstacles are preventing you to live a prosperous Life.",
    },
    low: {
      label: "Seriously unbalanced",
      description:
        "Warning! This suggests that your Life is significantly unbalanced. Act now!",
    },
  },
  growth: {
    high: {
      label: "Excellent",
      description:
        "Ave Master! This suggests that your skills to grow personally and professionally are exceptional. For you only the sky is the limit!",
    },
    mediumHigh: {
      label: "High",
      description:
        "Hooray! This suggests that your skills to grow personally and professionally are high, yet there is more potential and talent in you to be develop!",
    },
    medium: {
      label: "Satisfactory",
      description:
        "Congratulation! This suggests that your skills to grow personally and professionally are sufficient, but there are some obstacles that prevent you to fully develop your potentials and talents.",
    },
    mediumLow: {
      label: "Unsatisfactory",
      description:
        "Heads-up! This suggests that there are significant obstacles preventing you to develop your personal and professional potentials.",
    },
    low: {
      label: "Seriously low",
      description:
        "Warning! This suggests that your unused personal and professional potentials might significantly undermines your Growth. Act now!",
    },
  },
  purpose: {
    high: {
      label: "Outstanding",
      description:
        "Ave Master! This suggests that your Life has a deep meaning and cause. You are fully fulfilled and a part of the bigger story!",
    },
    mediumHigh: {
      label: "Very meaningful",
      description:
        "Hooray! This suggests that your Life Purpose is very high, but you have more potential in yourself to live a fully fulfilled Life.",
    },
    medium: {
      label: "Meaningful",
      description:
        "Congratulation! This suggests that your Life has a Purpose, but some temporary or permanent obstacles are preventing you to fully fulfilled your Life.",
    },
    mediumLow: {
      label: "Slightly meaningful",
      description:
        "Heads-up! This suggests that your Life Purpose is not sufficient and that significant  obstacles are preventing you to fully fulfilled your Life.",
    },
    low: {
      label: "Not enough",
      description:
        "Warning! This suggests that you cannot find meaning and cause in your Life, what could significantly undermine your Life fulfilment. Act now!",
    },
  },
  happiness: {
    high: {
      label: "Extremely happy",
      description:
        "Ave Master! This suggests that you know how to live extremely  Happy Life without regrets! You have courage to live a life true to yourself!",
    },
    mediumHigh: {
      label: "Very happy",
      description:
        "Hooray! This suggests that you know how to live very Happy Life without regrets. Yet, you could discover even more about yourself and to live a life true to yourself.",
    },
    medium: {
      label: "Happy",
      description:
        "Congratulation! This suggests that you know how to live Happy Life but there are some obstacles that prevent you to live a life true to yourself.",
    },
    mediumLow: {
      label: "Slightly happy",
      description:
        "Heads-up! This suggests that your Life Happiness is not sufficient and that significant obstacles might prevent you not to live a Life true to yourself.",
    },
    low: {
      label: "Not at all happy",
      description:
        "Warning! This suggests that your Life Happiness is critically low. Act now! Be happy! Life is so wonderful!",
    },
  },
  workLife: {
    high: {
      label: "Excellent",
      description: "",
    },
    mediumHigh: {
      label: "High",
      description: "",
    },
    medium: {
      label: "Satisfactory",
      description: "",
    },
    mediumLow: {
      label: "Unsatisfactory",
      description: "",
    },
    low: {
      label: "Not enough",
      description: "",
    },
  },
  jobSatisfaction: {
    high: {
      label: "Extremely satisfied",
      description: "",
    },
    mediumHigh: {
      label: "Satisfied",
      description: "",
    },
    medium: {
      label: "Slightly satisfied",
      description: "",
    },
    mediumLow: {
      label: "Not enough satisfied",
      description: "",
    },
    low: {
      label: "Dissatisfied",
      description: "",
    },
  },
  workStress: {
    high: {
      label: "Excellent",
      description: "",
    },
    mediumHigh: {
      label: "Slightly stressful",
      description: "",
    },
    medium: {
      label: "Stressful, but still under control",
      description: "",
    },
    mediumLow: {
      label: "Very stressful",
      description: "",
    },
    low: {
      label: "Critically stressful",
      description: "",
    },
  },
};

export const levels = {
  grandMaster: {
    level: "Grand Master",
    explanation:
      "For you all is possible! Everything and anywhere! It’s also a huge responsibility, save the world is your life mission!",
  },
  almostGrandMaster: {
    level: "Almost Grand Master",
    explanation:
      "For you all is possible! Everything and anywhere! It’s also a huge responsibility, save the world is your life mission!",
  },
  knight: {
    level: "Knight",
    explanation:
      "You can become much more than you’ve ever thought was even possible! For you, only the sky is limit.",
  },
  almostKnight: {
    level: "Almost Knight",
    explanation:
      "You have extraordinary abilities to engage all energies and use them in balance with everything around you. Use the life force wisely!",
  },
  warior: {
    level: "Warrior",
    explanation:
      "You have high potential to engage all energies and to use them in balance with everything around you. Keep going!",
  },
  almostWarior: {
    level: "Almost Warrior",
    explanation:
      "Hooray! You are on right path from discovery to engagement of all your energies and using them in balance with everything around you.",
  },
  apprentice: {
    level: "Apprentice",
    explanation:
      "You are in discovery phase how to engage all your energies and use them in balance but with persistence and focus you will be on right path very soon!",
  },
};

export const reportFields = {
  energy: { slo: "Osebna energija", eng: "Personal Energy" },
  physical: { slo: "Fizična energija", eng: "Physical Energy" },
  mental: { slo: "Mentalna energija", eng: "Mental Energy" },
  emotional: { slo: "Čustvena energija", eng: "Emotional Energy" },
  soul: { slo: "Duhovna energija", eng: "Soul Energy" },
  spiritual: { slo: "Duhovna energija", eng: "Spiritual Energy" },
  lifeBalance: { slo: "Zivljensko ravnotezje", eng: "Life balance" },
  health: { slo: "aa", eng: "Health & Wellness" },
  finance: { slo: "Finančno počutje", eng: "Financial well-being" },
  family: { slo: "Družina & prijatelji", eng: "Family & Friends" },
  work: { slo: "Delo & kariera", eng: "Work & Career" },
  pleasure: { slo: "Radost & veselje", eng: "Joy & Pleasure" },
  personalGrowth: { slo: "Osebna rast", eng: "Personal Growth" },
  respectMyself: { slo: "Samospoštovanje", eng: "Respect myself" },
  selfAwarness: { slo: "Samozavedanje", eng: "Self-awareness" },
  personalDevelopment: { slo: "Osebni razvoj", eng: "Personal development" },
  selfActualisation: { slo: "Samouresničevanje", eng: "Self-actualization" },
  optimism: { slo: "Optimizem", eng: "Optimism" },

  professionalGrowth: { slo: "Strokovna rast", eng: "Professional Growth" },
  learningTraining: {
    slo: "Učenje, usposabljanje",
    eng: "Learning & training",
  },
  developmentOpportunities: {
    slo: "Priložnosti za rast in razvoj",
    eng: "Development opportunities",
  },
  careerAdvancement: {
    slo: "Karijerno napredovanje",
    eng: "Career advancement",
  },
  jobSuitability: { slo: "Ustreznost delovnega mesta", eng: "Job FIT" },
  companyFit: { slo: "Ustreznost podjetja", eng: "Company FIT" },

  purpose: { slo: "Življensko poslanstvo", eng: "Life purpose" },
  personal: { slo: "Osebno poslanstvo", eng: "Personal Purpose" },
  relationships: {
    slo: "Izpolnjenost skozi odnose",
    eng: "Through Relationships",
  },
  organisation: {
    slo: "Izpolnjenost s poslanstvom podjetja",
    eng: "Through Company",
  },
  society: { slo: "Gradnik družbe", eng: "Contributing to Society" },
  planet: { slo: "Vez z naravo", eng: "Caring for Nature" },

  happiness: { slo: "Srečno življenje", eng: "Happy life" },
  lifeQualitySatisfaction: {
    slo: "Življenjsko zadovoljstvo",
    eng: "Life satisfaction",
  },
  liveOutsideWork: { slo: "Življenje izven dela", eng: "Life beyond work" },
  lovedAndLoving: { slo: "Čutim ljubezen", eng: "Loved" },
  happyFulfiled: { slo: "Notranje izpolnjen(a)", eng: "Fulfilled" },
  noRegrets: { slo: "Brez obžalovanj", eng: "No regrets " },

  careerSignals: { slo: "Karierni signali", eng: "Career signals" },
  workLifeBalance: {
    slo: "Ravnovesje delo/življenje",
    eng: "Work-life balance",
  },
  jobSatisfaction: { slo: "Zadovoljstvo pri delu", eng: "Job satisfaction" },
  passionForWork: { slo: "Strast za delo", eng: "Passion for work" },
  careerSatisfaction: {
    slo: "Zadovoljstvo s kariero",
    eng: "Career satisfaction",
  },
  jobQuitSignals: {
    slo: "Ostajam ali odhajam iz podjetja",
    eng: "Job quit signals",
  },

  stressSignals: { slo: "Signali stresa", eng: "Stress signals" },
  workStress: { slo: "Stress pri delu", eng: "Work stress" },
  burnOut: {
    slo: "Signali za izgorelost pri delu",
    eng: "Burn-out stressors at work",
  },
  stressInPrivateLife: {
    slo: "Stres v zasebnem življenju",
    eng: "Stress in Private life",
  },
  burnOutPrivatLife: {
    slo: "Signali za izgorelost v zasebnem življenju",
    eng: "Burn-out stressors in private life",
  },
  burnOutResilience: {
    slo: "Odpornost na izgorelost",
    eng: "Burn-out Resilience",
  },
};

// communityCountry - society
