import React from "react";

import Grid from "@material-ui/core/Grid";

import Chart from "react-apexcharts";

import { levels } from "../reports-common-utils/constants";

import {
  Divider,
  IconTitleAndSubtittle,
  IconAndTitle,
  IconLeftTitle,
  CenterElements,
  getOptionsForValue,
  Score,
  SmileyVertical,
  getScoreLabelWorkLife,
  getScoreLabelJobSatisfaction,
  getScoreLabelWorkStress,
  getExplanationForPulseAndScore,
  getLabelforPulseAndLevel
} from "../reports-common-utils/";

import { standardCircle } from "../../common/chartOptions";

const BaseReport = function(props) {
  const { classes, reportData } = props;
  const legendItem = {
    flex: 1
  };
  return (
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <h1>Congratulations! That’s your 5 Pulses:</h1>
      </Grid>
      <Grid item xs={5}>
        <IconAndTitle
          icon={"energy"}
          lightDesc={"1st pulse"}
          title={"My energy"}
          subTitle={"Am I enough energized to achieving my best-self?"}
        />
      </Grid>
      <Grid item xs={7}>
        <p>
          The first Pulse shows how much energized you are in this moment. Did
          you know that to bring your best energy to work and life you have to
          be physically energized, mentally focused, emotionally balanced, wired
          to your soul, and spiritually aligned?
        </p>
      </Grid>
      <Divider height={16} />
      {/* break */}

      {/* ====== ENERGY SECTION START ====== */}
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <div className={classes.boxItem}>
            <div className={classes.boxItemInner}>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <span
                    className={[classes.lightText, classes.textCenter].join(
                      " "
                    )}
                  >
                    Your current energies to help you increase your levels of
                    energy in all five dimensions:
                  </span>
                </Grid>
              </Grid>
              <Divider height={16} />
              <Grid container spacing={8}>
                <Grid item xs={3}>
                  <CenterElements>
                    <div className={classes.pullUp}>
                      <Chart
                        options={getOptionsForValue(reportData.energy)}
                        series={[reportData.energy]}
                        type="radialBar"
                        width={300}
                        height={300}
                        colors={["#68f989"]}
                        fill={{
                          colors: ["#68f989", "#68f989", "#68f989"]
                        }}
                      />
                    </div>
                  </CenterElements>
                  <Score pulse="energy" score={reportData.energy} />
                </Grid>
                <Grid item xs={9}>
                  <div className="box-container">
                    <div className="box-item">
                      <IconTitleAndSubtittle
                        icon={"physical"}
                        title={"Physical"}
                        subTitle={"Supports your health and wellbeing."}
                      />
                      <CenterElements>
                        <Chart
                          options={standardCircle}
                          series={[reportData.physical]}
                          type="radialBar"
                          width={150}
                          height={150}
                        />
                      </CenterElements>
                    </div>
                    <div className="box-item">
                      <IconTitleAndSubtittle
                        icon={"mental"}
                        title={"Mental"}
                        subTitle={"Increases your focus and inner peace."}
                      />
                      <CenterElements>
                        <Chart
                          options={standardCircle}
                          series={[reportData.mental]}
                          type="radialBar"
                          width={150}
                          height={150}
                        />
                      </CenterElements>
                    </div>
                    <div className="box-item">
                      <IconTitleAndSubtittle
                        icon={"emotional"}
                        title={"Emotional"}
                        subTitle={"Balances your sentiments and relationships."}
                      />
                      <CenterElements>
                        <Chart
                          options={standardCircle}
                          series={[reportData.emotional]}
                          type="radialBar"
                          width={150}
                          height={150}
                        />
                      </CenterElements>
                    </div>
                    <div className="box-item">
                      <IconTitleAndSubtittle
                        icon={"soul"}
                        title={"Soul"}
                        subTitle={"Boosts your creativity and pleasure."}
                      />
                      <CenterElements>
                        <Chart
                          options={standardCircle}
                          series={[reportData.soul]}
                          type="radialBar"
                          width={150}
                          height={150}
                        />
                      </CenterElements>
                    </div>
                    <div className="box-item">
                      <IconTitleAndSubtittle
                        icon={"spiritual"}
                        title={"Spiritual"}
                        subTitle={"Ignite your life meaning and purpose."}
                      />
                      <CenterElements>
                        <Chart
                          options={standardCircle}
                          series={[reportData.spiritual]}
                          type="radialBar"
                          width={150}
                          height={150}
                        />
                      </CenterElements>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>

      {/* ====== ENERGY SECTION END ====== */}
      {/* ====== LEFE BALANCE SECTION START ====== */}
      <Divider height={16} />
      <Grid item xs={5}>
        <IconAndTitle
          icon={"life-balance"}
          lightDesc={"2nd pulse"}
          title={"My life balance"}
          subTitle={"Am I living in balance?"}
        />
      </Grid>
      <Grid item xs={7}>
        <p>
          The second Pulse is about how successful you are balancing the most
          important aspects of your life, from health to work. Life balance
          needs continuous maintenance and effort and this is why a snapshot of
          your current Life balance is so important.
        </p>
      </Grid>
      <Divider height={16} />

      <Grid container spacing={24}>
        <Grid item xs={12}>
          <div className={classes.boxItem}>
            <div className={classes.boxItemInner}>
              <Grid container spacing={8}>
                <Grid item xs={3}>
                  <CenterElements>
                    <div className={classes.pullUp}>
                      <Chart
                        options={getOptionsForValue(reportData.lifeBalance)}
                        series={[reportData.lifeBalance]}
                        type="radialBar"
                        width={300}
                        height={300}
                        colors={["#68f989"]}
                        fill={{
                          colors: ["#68f989", "#68f989", "#68f989"]
                        }}
                      />
                    </div>
                  </CenterElements>
                  <Score pulse="lifeBalance" score={reportData.lifeBalance} />
                </Grid>
                <Grid item xs={9}>
                  <div className="box-container">
                    <div className="box-item">
                      <IconTitleAndSubtittle
                        icon={"health"}
                        title={"Health"}
                        subTitle={"The enabler of your life."}
                      />
                      <CenterElements>
                        <Chart
                          options={standardCircle}
                          series={[reportData.health]}
                          type="radialBar"
                          width={150}
                          height={150}
                        />
                      </CenterElements>
                    </div>
                    <div className="box-item">
                      <IconTitleAndSubtittle
                        icon={"finance"}
                        title={"Finance"}
                        subTitle={"Independence and decent life."}
                      />
                      <CenterElements>
                        <Chart
                          options={standardCircle}
                          series={[reportData.finance]}
                          type="radialBar"
                          width={150}
                          height={150}
                        />
                      </CenterElements>
                    </div>
                    <div className="box-item">
                      <IconTitleAndSubtittle
                        icon={"family"}
                        title={"Family"}
                        subTitle={"Your nearest and dearest."}
                      />
                      <CenterElements>
                        <Chart
                          options={standardCircle}
                          series={[reportData.family]}
                          type="radialBar"
                          width={150}
                          height={150}
                        />
                      </CenterElements>
                    </div>
                    <div className="box-item">
                      <IconTitleAndSubtittle
                        icon={"work"}
                        title={"Work"}
                        subTitle={"Significant part of your life."}
                      />
                      <CenterElements>
                        <Chart
                          options={standardCircle}
                          series={[reportData.work]}
                          type="radialBar"
                          width={150}
                          height={150}
                        />
                      </CenterElements>
                    </div>
                    <div className="box-item">
                      <IconTitleAndSubtittle
                        icon={"pleasure"}
                        title={"Pleasure"}
                        subTitle={"The “spice” of your life."}
                      />
                      <CenterElements>
                        <Chart
                          options={standardCircle}
                          series={[reportData.pleasure]}
                          type="radialBar"
                          width={150}
                          height={150}
                        />
                      </CenterElements>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
      <Divider height={16} />
      {/* ====== LIFE BALANCE SECTION END ====== */}
      {/* ====== GROWTH POTENTIAL SECTION START ====== */}
      <Grid item xs={5}>
        <IconAndTitle
          icon={"growth-potential"}
          lightDesc={"3rd pulse"}
          title={"My growth potential"}
          subTitle={"Am I living my potentials?"}
        />
      </Grid>
      <Grid item xs={7}>
        <p>
          The third Pulse taps into your Growth potential to better understand
          what limits you and how to move forward in the future, both in terms
          of your professional and personal development path, from
          self-awareness, to optimism.
        </p>
      </Grid>
      <Divider height={16} />

      {/* ====== PERSONAL GROWTH SECTION START ====== */}
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <div className={classes.boxItem}>
            <div className={classes.boxItemInner}>
              <Grid container spacing={8}>
                <Grid item xs={3}>
                  <IconLeftTitle
                    icon={"personal-dev"}
                    title={"Personal"}
                    subTitle={"growth"}
                  />
                  <CenterElements>
                    <div className={classes.pullUp}>
                      <Chart
                        options={getOptionsForValue(reportData.personalGrowth)}
                        series={[reportData.personalGrowth]}
                        type="radialBar"
                        width={300}
                        height={300}
                        colors={["#68f989"]}
                        fill={{
                          colors: ["#68f989", "#68f989", "#68f989"]
                        }}
                      />
                    </div>
                  </CenterElements>
                  <Score pulse="workLife" score={reportData.personalGrowth} />
                </Grid>
                <Grid item xs={9}>
                  <div className="box-container">
                    <div className="box-item">
                      <SmileyVertical
                        size={"large"}
                        title={"Respect myself"}
                        description={"You say and do what you mean."}
                        score={reportData.respectMyself}
                      />
                    </div>
                    <div className="box-item">
                      <SmileyVertical
                        size={"large"}
                        title={"Self awarness"}
                        description={"You know who you are and what you want."}
                        score={reportData.selfAwarness}
                      />
                    </div>
                    <div className="box-item">
                      <SmileyVertical
                        size={"large"}
                        title={"Personal development"}
                        description={
                          "You invest time and resources for yourself."
                        }
                        score={reportData.personalDevelopment}
                      />
                    </div>
                    <div className="box-item">
                      <SmileyVertical
                        size={"large"}
                        title={"Self-actualization"}
                        description={
                          "You are proud, accomplished, and fulfilled."
                        }
                        score={reportData.selfActualisation}
                      />
                    </div>
                    <div className="box-item">
                      <SmileyVertical
                        size={"large"}
                        title={"Optimism"}
                        description={
                          "You are looking forward into the future. "
                        }
                        score={reportData.optimism}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
      <Divider height={16} />
      {/* ====== PERSONAL GROWTH SECTION END ====== */}
      {/* ====== PROFESSIONAL GROWTH SECTION START ====== */}
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <div className={classes.boxItem}>
            <div className={classes.boxItemInner}>
              <Grid container spacing={8}>
                <Grid item xs={3}>
                  <IconLeftTitle
                    icon={"prof-dev"}
                    title={"Professional"}
                    subTitle={"growth"}
                  />
                  <CenterElements>
                    <div className={classes.pullUp}>
                      <Chart
                        options={getOptionsForValue(
                          reportData.professionalGrowth
                        )}
                        series={[reportData.professionalGrowth]}
                        type="radialBar"
                        width={300}
                        height={300}
                        colors={["#68f989"]}
                        fill={{
                          colors: ["#68f989", "#68f989", "#68f989"]
                        }}
                      />
                    </div>
                  </CenterElements>
                  <Score
                    pulse="workLife"
                    score={reportData.professionalGrowth}
                  />
                </Grid>
                <Grid item xs={9}>
                  <div className="box-container">
                    <div className="box-item">
                      <SmileyVertical
                        size={"large"}
                        title={"Learning"}
                        titleSecondLine={"& Training"}
                        description={"Life-long necessity."}
                        score={reportData.learningTraining}
                      />
                    </div>
                    <div className="box-item">
                      <SmileyVertical
                        size={"large"}
                        title={"Development "}
                        titleSecondLine={"opportunities"}
                        description={"Apply talent and expertise."}
                        score={reportData.developmentOpportunities}
                      />
                    </div>
                    <div className="box-item">
                      <SmileyVertical
                        size={"large"}
                        title={"Career"}
                        titleSecondLine={"advancement"}
                        description={"Space to grow and shine."}
                        score={reportData.careerAdvancement}
                      />
                    </div>
                    <div className="box-item">
                      <SmileyVertical
                        size={"large"}
                        title={"Job"}
                        titleSecondLine={"suitability"}
                        description={"You have a dream job."}
                        score={reportData.jobSuitability}
                      />
                    </div>
                    <div className="box-item">
                      <SmileyVertical
                        size={"large"}
                        title={"Company"}
                        titleSecondLine={"Fit"}
                        description={"You are in the right place."}
                        score={reportData.companyFit}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
      <Divider height={16} />
      {/* ====== PROFESSIONAL GROWTH SECTION END ====== */}

      {/* ====== GROWTH POTENTIAL SECTION END ====== */}
      {/* ====== MY PURPOSE SECTION START ====== */}

      <Grid item xs={5}>
        <IconAndTitle
          icon={"purpose"}
          lightDesc={"4th pulse"}
          title={"My purpose"}
          subTitle={"Am I living meaningful life?"}
        />
      </Grid>
      <Grid item xs={7}>
        <p>
          The fourth Pulse explores how much deeply meaningful life you live and
          whether you are doing what you really want to do in your life,
          regardless if this is related to your personal life, your family,
          organization, community or to the planet Earth.
        </p>
      </Grid>
      <Divider height={16} />

      <Grid container spacing={24}>
        <Grid item xs={12}>
          <div className={classes.boxItem}>
            <div className={classes.boxItemInner}>
              <Grid container spacing={8}>
                <Grid item xs={3}>
                  <CenterElements>
                    <div className={classes.pullUp}>
                      <Chart
                        options={getOptionsForValue(reportData.purpose)}
                        series={[reportData.purpose]}
                        type="radialBar"
                        width={300}
                        height={300}
                        colors={["#68f989"]}
                        fill={{
                          colors: ["#68f989", "#68f989", "#68f989"]
                        }}
                      />
                    </div>
                  </CenterElements>
                  <Score pulse="purpose" score={reportData.purpose} />
                </Grid>
                <Grid item xs={9}>
                  <div className="box-container">
                    <div className="box-item">
                      <IconTitleAndSubtittle
                        icon={"personal"}
                        title={"Personal"}
                        subTitle={"My private life has meaning and cause."}
                      />
                      <CenterElements>
                        <Chart
                          options={standardCircle}
                          series={[reportData.personal]}
                          type="radialBar"
                          width={150}
                          height={150}
                        />
                      </CenterElements>
                    </div>
                    <div className="box-item">
                      <IconTitleAndSubtittle
                        icon={"relationship"}
                        title={"Relationships"}
                        subTitle={
                          "Happy, meaningful, and fulfilled relationships."
                        }
                      />
                      <CenterElements>
                        <Chart
                          options={standardCircle}
                          series={[reportData.relationships]}
                          type="radialBar"
                          width={150}
                          height={150}
                        />
                      </CenterElements>
                    </div>
                    <div className="box-item">
                      <IconTitleAndSubtittle
                        icon={"organization"}
                        title={"Organization"}
                        subTitle={"Feel aligned and part of something bigger."}
                      />
                      <CenterElements>
                        <Chart
                          options={standardCircle}
                          series={[reportData.organisation]}
                          type="radialBar"
                          width={150}
                          height={150}
                        />
                      </CenterElements>
                    </div>
                    <div className="box-item">
                      <IconTitleAndSubtittle
                        icon={"community"}
                        title={"Community & country"}
                        subTitle={"I contribute and feel safe."}
                      />
                      <CenterElements>
                        <Chart
                          options={standardCircle}
                          series={[reportData.communityCountry]}
                          type="radialBar"
                          width={150}
                          height={150}
                        />
                      </CenterElements>
                    </div>
                    <div className="box-item">
                      <IconTitleAndSubtittle
                        icon={"planet"}
                        title={"Planet"}
                        subTitle={"I protect and feel connected with nature."}
                      />
                      <CenterElements>
                        <Chart
                          options={standardCircle}
                          series={[reportData.planet]}
                          type="radialBar"
                          width={150}
                          height={150}
                        />
                      </CenterElements>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
      <Divider height={16} />
      {/* ====== MY PURPOSE SECTION END ====== */}
      {/* ====== MY HAPPINES SECTION START ====== */}
      <Grid item xs={5}>
        <IconAndTitle
          icon={"happiness"}
          lightDesc={"5th pulse"}
          title={"My happiness"}
          subTitle={"Am I fully living my life?"}
        />
      </Grid>
      <Grid item xs={7}>
        <p>
          It’s important to be reminded that whatever stage we are at in life,
          there is no need for regret. When it comes to making changes, be at
          peace with the past and remember that each moment is a new choice. The
          fifth pulse will help you overcome your fears and become a more
          courageous “version” of yourself.
        </p>
      </Grid>
      <Divider height={8} />

      <Grid container spacing={24}>
        <Grid item xs={12}>
          <div className={classes.boxItem}>
            <div className={classes.boxItemInner}>
              <Grid container spacing={8}>
                <Grid item xs={3}>
                  <CenterElements>
                    <div className={classes.pullUp}>
                      <Chart
                        options={getOptionsForValue(reportData.happiness)}
                        series={[reportData.happiness]}
                        type="radialBar"
                        width={300}
                        height={300}
                        colors={["#68f989"]}
                        fill={{
                          colors: ["#68f989", "#68f989", "#68f989"]
                        }}
                      />
                    </div>
                  </CenterElements>
                  <Score pulse="happiness" score={reportData.happiness} />
                </Grid>
                <Grid item xs={9}>
                  <div className="box-container">
                    <div className="box-item">
                      <SmileyVertical
                        size={"large"}
                        title={"I am satisfied with"}
                        titleSecondLine={"quality of life"}
                        score={reportData.lifeQualitySatisfaction}
                      />
                    </div>
                    <div className="box-item">
                      <SmileyVertical
                        size={"large"}
                        title={"I live also outside"}
                        titleSecondLine={"of work"}
                        score={reportData.liveOutsideWork}
                      />
                    </div>
                    <div className="box-item">
                      <SmileyVertical
                        size={"large"}
                        title={"I am loved and"}
                        titleSecondLine={"I am loving"}
                        score={reportData.lovedAndLoving}
                      />
                    </div>
                    <div className="box-item">
                      <SmileyVertical
                        size={"large"}
                        title={"I am happy"}
                        titleSecondLine={"and fulfilled"}
                        score={reportData.happyFulfiled}
                      />
                    </div>
                    <div className="box-item">
                      <SmileyVertical
                        size={"large"}
                        title={"I have no regrets that"}
                        titleSecondLine={"have not lived fully"}
                        score={reportData.noRegrets}
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
      <Divider height={16} />
      {/* ====== MY HAPPINES SECTION END ====== */}
      {/* ====== LIFE FORCE SECTION START ====== */}

      <Grid container spacing={8}>
        <Grid item xs={4}>
          <div className={classes.boxItem}>
            <div className={classes.boxItemInner}>
              <SmileyVertical
                size={"large"}
                title={"JOB SATISFACTION"}
                level={getScoreLabelJobSatisfaction(reportData.jobSatisfaction)}
                label={
                  getLabelforPulseAndLevel(
                    "jobSatisfaction",
                    getScoreLabelJobSatisfaction(reportData.jobSatisfaction)
                  ).label
                }
              />
              <SmileyVertical
                size={"large"}
                title={"WORK-LIFE BALANCE"}
                level={getScoreLabelWorkLife(reportData.workLifeBalance)}
                label={
                  getLabelforPulseAndLevel(
                    "workLife",
                    getScoreLabelWorkLife(reportData.workLifeBalance)
                  ).label
                }
              />
              <SmileyVertical
                size={"large"}
                title={"WORK STRESS"}
                level={getScoreLabelWorkStress(reportData.workStress)}
                label={
                  getLabelforPulseAndLevel(
                    "workStress",
                    getScoreLabelWorkStress(reportData.workStress)
                  ).label
                }
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.boxItem}>
            <div className={classes.boxItemInner}>
              <IconAndTitle
                icon={"life-force"}
                lightDesc={"Special pulse"}
                title={"MY LIFE FORCE"}
                subTitle={"Am I thriving and evolving in my Life?"}
              />
              <p>
                “Quintaum LIFE FORCE” is not a Pulse but rather a hint
                reflecting the engagement of all energies in all dimensions of
                your life, your capacity to thrive and evolve on the long run.
                Although it may sound fun, this signal is here to significantly
                boost your awareness about yourself and to help you become much
                more than you’ve ever thought was even possible!
              </p>
              <Divider height={20} />
              <center>
                <h4>YOU'RE A</h4>
                <h2>{reportData.level && levels[reportData.level].level}</h2>
                <p>
                  {reportData.level && levels[reportData.level].explanation}
                </p>
              </center>
            </div>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.boxItem}>
            <div className={classes.boxItemInner}>
              <h2 className={classes.allCapsTitle}>Life is so awesome</h2>
              <p className={classes.fontSizeNormal}>
                Life is so awesome but with everyday stress we usually forget
                how important are our Health, Love, Family, Joy, our Talents,
                and living a truly Meaningful Life.
              </p>
              <p>
                Take the personalized 5 Pulses reports as “a kind reminder”
                reflecting the five most important Pulses of your Life. Don’t
                get too stressed if there might be some red flags in the
                reports. We cannot balance and have everything in every moment
                of our Life. However, we should be aware of our vulnerabilities
                to be able to improve, balance, and take back the control of our
                Life!
              </p>
              <p>
                <b>Note:</b> Subject to selected corporate package, You might
                receive more detailed report after the Quintaum diagnostic is
                finished in your Organization.
              </p>
              <p>
                <b>Disclaimer:</b> The results are based on Quintaum Diagnostic,
                however, the results accuracy and relevance is subject of the
                entry information submitted from your side during the survey.
              </p>
            </div>
          </div>
        </Grid>
      </Grid>

      {/* ====== LIFE FORCE SECTION END ====== */}
      <Divider height={50} />
      <Grid container spacing={8}>
        <Grid item xs={8} />
        <Grid item xs={4}>
          <div class="box-container">
            <div class="box-item">
              <div style={{ textAlign: "center" }}>
                <img src="/assets/img/the-worst.png" />
                <p>super bad</p>
              </div>
            </div>
            <div class="box-item">
              <div style={{ textAlign: "center" }}>
                <img src="/assets/img/bad.png" />
                <p>bad</p>
              </div>
            </div>
            <div class="box-item">
              <div style={{ textAlign: "center" }}>
                <img src="/assets/img/so-so.png" />
                <p>so-so</p>
              </div>
            </div>
            <div class="box-item">
              <div style={{ textAlign: "center" }}>
                <img src="/assets/img/good.png" />
                <p>good</p>
              </div>
            </div>
            <div class="box-item">
              <div style={{ textAlign: "center" }}>
                <img src="/assets/img/the-best.png" />
                <p>great</p>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <Divider height={20} />
    </Grid>
  );
};

export default BaseReport;
