import { createMuiTheme } from "@material-ui/core/styles";
//import "typeface-roboto";
import blue from "@material-ui/core/colors/blue";

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      //main: "#c44a67"
      main: "#1127ff"
      //main: "#ec407a"
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: blue
  },
  overrides: {
    MuiButton: {
      // Name of the rule
      root: {
        // Some CSS
        /* background:
          "linear-gradient(135deg, #485dfc, #6f48fc, #8996f7, #6f48fc)", */
        //backgroundColor: "#61bdfb",
        borderRadius: 3,
        border: 0,
        color: "white",
        height: 36,
        padding: "0 30px"
        /* boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)" */
      },
      disabled: {
        color: "black",
        backgroundColor: "#eee"
        /* background: "linear-gradient(45deg, #eee 30%, #eee 90%)" */
      },
      contained2: {
        boxShadow:
          "0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 1px -2px rgba(0, 0, 0, 0.12);"
      }
    },
    MuiPaper: {
      root: {
        /* boxShadow:
          "0px 1px 1px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)" */
        boxShadow: "none",
        background: "#1127ff"
        //opacity: "0.02"
      },
      elevation1: {
        /*  boxShadow:
          "0px 1px 1px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 1px -1px rgba(0, 0, 0, 0.12)" */
        boxShadow: "none"
      },
      elevation2: {
        /*  boxShadow:
          "0px 1px 1px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 1px -1px rgba(0, 0, 0, 0.12)" */
        boxShadow: "none"
      },
      elevation4: {
        /* boxShadow:
          "0px 1px 1px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 1px -1px rgba(0, 0, 0, 0.12);" */
        boxShadow: "none"
      }
    },
    MuiGrid: {
      item: {
        /* background: "rgba(17,39,255, 0.02)" */
      }
    },
    MuiAppBar: {
      root: {
        /* backgroundImage:
          "linear-gradient(135deg, #485dfc, #6f48fc, #8996f7, #6f48fc)" */
        //backgroundImage: "linear-gradient(90deg, #ec407a, #FF8E53)"
      }
    }
  }
});

export default theme;
